import {
  SET_SUBMITTING,
} from '../types';


export function setCheckedInSubmitting(key, isSubmitting) {
  const submittingKey = 'checked_in';
  return function(dispatch, API, getState) {
    const { submitting } = getState();

    dispatch(setDataSubmitting(submittingKey, {
      ...submitting[submittingKey],
      [key]: isSubmitting,
    }));
  };
}

export function setCheckedOutSubmitting(key, isSubmitting) {
  const submittingKey = 'checked_out';
  return function(dispatch, API, getState) {
    const { submitting } = getState();

    dispatch(setDataSubmitting(submittingKey, {
      ...submitting[submittingKey],
      [key]: isSubmitting,
    }));
  };
}


export function setDataSubmitting(key, isSubmitting) {
  return {
    type: SET_SUBMITTING,
    payload: {
      [key]: isSubmitting,
    },
  };
}
