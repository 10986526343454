import ApiGroup from './ApiGroup';

export default class surveyAPI extends ApiGroup {
  constructor() {
    super({
      name: 'survey',
    });
  }

    submit = (data, access_key) => {
      return this.post({
        endpoint: `/upload`,
        data,
      },
      {
        headers: {
          'authorization': access_key,
        },
      });
    };

    postDriveSurveyLink = (data) => {
      this.post({
        endpoint: '/post/drive/link',
        data,
      });
    };

    postDriveLinkClick = (data) => {
      return this.post({
        endpoint: '/post/drive/link/click',
        data,
      });
    };

    phoneVerification = (data) => {
      return this.post({
        endpoint: '/verify/phone',
        data,
      });
    };

    emailVerification = (data) => {
      return this.post({
        endpoint: '/verify/email',
        data,
      });
    };
}
