
/*
* Try to keep in the same order as
* the form so it's easy to match up
*/

const fieldKeys = [
  'survey_id',
  'device_survey_guid',
  'driven_vehicles',
  'initials',
];

const fieldKeysRequired = [
  'driven_vehicles',
  'initials',
];

export default {
  fieldKeys,
  defaultValues: {
    survey_id: '',
    device_survey_guid: '',

    driven_vehicles: [],
    initials: '',
  },
  validate(fields) {
    console.log('fields CONFIG :', fields);
    const errors = {};

    fieldKeysRequired.forEach(key => {
      if (fields[key] === '' || fields[key] === null) {
        errors[key] = '*Required';
      }
    });

    if (fields.initials) {
      if (fields.initials.length < 2) {
        errors.initials = 'These initials are incomplete.';
      }

      if (!(fields.initials).match(/^[a-zA-Z]*$/)) {
        errors.initials = 'Initials must include only letters';
      }
    }

    if (fields.driven_vehicles) {
      if (!fields.driven_vehicles.length) {
        errors.driven_vehicles = 'Please choose at least one driven vehicle.';
      }
    }

    if (fields.bonus_event) {
      if (fields.drove_bonus_vehicle === null && fields.driven_vehicles.length > 1) {
        errors.drove_bonus_vehicle = 'Bonus Drive response is required.';
      }
    }

    return errors;
  },
};
