import ApiGroup from './ApiGroup';

export default class eventsApi extends ApiGroup {
  constructor() {
    super({
      name: 'event',
    });
  }

    trainingComplete = (data) => (
      this.post({
        endpoint: `/training/complete`,
        data,
      })
    );

    trainingShare = (data) => (
      this.post({
        endpoint: `/training/share`,
        data,
      })
    );


    getEvent = (data) => (
      this.post({
        endpoint: `/microsite/check`,
        data,
      })
    );

    end = (data) => (
      this.post({
        endpoint: `/end`,
        data,
      })
    );

    generateQRCode = (hashId) => (
      this.get(`/build-qr?hashId=${hashId}`)
    );

    generateFlyer = (eventId) => (
      this.get(`/build-flyer?eventId=${eventId}`)
    );

    generateStartFlyer = (eventId) => (
      this.get(`/start-here-flyer?eventId=${eventId}`)
    );

    generateFlyerSp = (eventId) => (
      this.get(`/build-flyer-sp?eventId=${eventId}`)
    );

    generateStartFlyerSp = (eventId) => (
      this.get(`/start-here-flyer-sp?eventId=${eventId}`)
    );

    getStats = (data) => (
      this.post({
        endpoint: `/stats`,
        data,
      })
    );
    
    clickedDemo = (data) => (
      this.post({
        endpoint: `/clicked-demo`,
        data,
      })
    );
}
