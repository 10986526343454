
/*
* Try to keep in the same order as
* the form so it's easy to match up
*/

import uuid from 'uuid';

const deviceSurveyGuid = uuid.v1();

const fieldKeys = [
  'event_id',
  'event_name',
  'device_survey_guid',
  'start_time',
  'event_series',
  'survey_type',
  'pre_drive_survey_guid',
  'dealer_portal_entry',
  'checked_in',
  'checked_out',

  /* Pre*/
  'first_name',
  'last_name',
  'address1',
  'address2',
  'city',
  'state',
  'zip_code',
  'phone',
  'email',
  'accepts_sms',
  'email_opt_in',
  'which_td',
  'impression_pre',
  'hands_free_familiarity',
  'hands_free_capabilities',
  'drive_scenarios',
  'how_likely_purchasing_pre',
  'in_market_timing',
  'how_likely_acquire',
  'how_likely_recommend_pre',
  'signature',
  'selected_charity',
  'pre_drive_legal',
  'initials',
  'certify_age',
  'language',
  'comfort_level_pre',
  'vehicle_driven_most_make_id',

  /* Post*/
  'vehicles_of_interest',
  'impression_post',
  'how_likely_purchasing_post',
  'how_likely_recommend_post',
  'age',
  'gender',
  'ethnicity',
  'custom_data',
  'brochure_language',
  'noLandlines',
  'comfort_level_post',
  'overall_physical_experience',
  'overall_virtual_experience',
  'virtual_improvements',
  'bluecruise_satisfaction',
  'bluecruise_enjoy',
  'bluecruise_dislike',
  'bluecruise_must_have',
];

export default {
  fieldKeys,
  defaultValues: {
    event_id: '',
    event_name: '',
    device_survey_guid: deviceSurveyGuid,
    start_time: '',
    event_series: '',
    survey_type: '',
    pre_drive_survey_guid: '',
    dealer_portal_entry: false,
    checked_in: null,
    checked_out: null,

    /* Pre*/
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    email: '',
    accepts_sms: '',
    email_opt_in: '',
    which_td: '',
    impression_pre: '',
    hands_free_familiarity: '',
    hands_free_capabilities: [],
    drive_scenarios: [],
    how_likely_purchasing_pre: '',
    in_market_timing: '',
    how_likely_acquire: '',
    how_likely_recommend_pre: '',
    signature: '',
    selected_charity: '',
    pre_drive_legal: false,
    initials: '',
    certify_age: false,
    language: '',
    comfort_level_pre: '',
    vehicle_driven_most_make_id: '',

    /* Post*/
    vehicles_of_interest: [],
    impression_post: '',
    how_likely_purchasing_post: '',
    how_likely_recommend_post: '',
    age: '',
    gender: '',
    ethnicity: '',
    custom_data: '',
    brochure_language: null,
    noLandlines: null,
    comfort_level_post: '',
    overall_physical_experience: '',
    overall_virtual_experience: '',
    virtual_improvements: [],
    bluecruise_satisfaction: '',
    bluecruise_enjoy: '',
    bluecruise_dislike: '',
    bluecruise_must_have: '',
  },
};
