export const basePath = `/mobile`;

export const getApiURL = (ENV = process.env.NODE_ENV, path = basePath) => {
  const domain = `${process.env.API_DOMAIN}${path}`;
  const isLocalhost = process.env.API_DOMAIN.indexOf('localhost') > -1 || /\d+\.\d+\.\d+\.\d+/g.test(process.env.API_DOMAIN);

  let protocol = 'https://';

  if (isLocalhost || (ENV !== 'staging' && ENV !== 'production' && ENV !== 'demo')) {
    protocol = 'http://';
  }

  return `${protocol}${domain}`;
};

export const API_URL = getApiURL();

