import nap from 'utils/nap';

import {
  SET_CHECK_IN_DATA,
  TOGGLE_CHECKED_IN,
} from '../types';

import {
  setCheckInDataLoading,
  setCheckedInSubmitting,
} from './';

export function checkIn(survey) {
  return function(dispatch, API) {
    const {
      survey_id,
      checked_in: _checked_in,
    } = survey;

    const checked_in = !_checked_in;

    dispatch(setCheckedInSubmitting(survey_id, true));

    return nap(300, API.check.in({ survey_id, checked_in }))
      .then(() => {
        dispatch({
          type: TOGGLE_CHECKED_IN,
          payload: {
            ...survey,
            checked_in,
          },
        });

        dispatch(setCheckedInSubmitting(survey_id, false));

        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(setCheckedInSubmitting(survey_id, false));
        return Promise.reject(error);
      });
  };
}

export function checkInData(trackLoading = true) {
  return function(dispatch, API, getState) {
    const { event_id } = getState().event;

    const time = trackLoading ? 400 : 0;

    trackLoading && dispatch(setCheckInDataLoading(true));

    return nap(time, API.check.in_data({ event_id }))
      .then(response => {
        response.data.forEach((survey) => {
          if (survey.email) {
            survey.email = survey.email.replace(/[^a-zA-Z0-9]/g, '');
          }
          if (survey.phone) {
            survey.phone = survey.phone.replace(/[^a-zA-Z0-9]/g, '');
          }
        });
        dispatch({
          type: SET_CHECK_IN_DATA,
          payload: response.data,
        });

        trackLoading && dispatch(setCheckInDataLoading(false));
        return Promise.resolve(true);
      })
      .catch((error) => {
        trackLoading && dispatch(setCheckInDataLoading(false));
        return Promise.reject(error);
      });
  };
}
// setCheckedInSubmitting
export function clearCheckInData() {
  return {
    type: SET_CHECK_IN_DATA,
    payload: {},
  };
}
