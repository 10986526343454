import JWT from 'utils/JWT';

import {
  SET_LANGUAGE,
  SET_USER_AUTH,
  UPDATE_STATUS,
  SET_SEASON,
  SET_GLOBAL_SETTINGS,
} from '../types';

import { setDataSubmitting } from './submitting.actions';

export function setLanguage(payload) {
  return {
    type: SET_LANGUAGE,
    payload,
  };
}

export function setSeason(payload) {
  return {
    type: SET_SEASON,
    payload,
  };
}

export function setGlobalSettings(payload) {
  return {
    type: SET_GLOBAL_SETTINGS,
    payload,
  };
}

export function sendInviteEmails(email_addresses) {
  return function(dispatch, API) {
    dispatch(setDataSubmitting('training_email', true));

    return API.event.trainingShare({ email_addresses })
      .then(() => {
        return dispatch(setDataSubmitting('training_email', false));
      })
      .catch(({ response }) => {
        console.log(response.data);
        dispatch(setDataSubmitting('training_email', false));
        return Promise.reject(response.data);
      });
  };
}

export function handleSendLoginLink(phone) {
  const cleaned = (`${phone}`).replace(/\D/g, '');

  return function(dispatch, API) {
    dispatch({
      type: UPDATE_STATUS,
      payload: {
        sms_status: {
          loading: true,
          message: 'Sending SMS Link...',
          type: 'alert',
        },

      },
    });
    return API.auth.handleSMSLogin({ phone: cleaned })
      .then(() => {
        dispatch({
          type: UPDATE_STATUS,
          payload: {
            sms_status: {
              loading: false,
              message: 'Successfully sent SMS login link!',
              type: 'success',
            },

          },
        });
        return Promise.resolve(true);
      })
      .catch(response => {
        dispatch({
          type: UPDATE_STATUS,
          payload: {
            sms_status: {
              loading: false,
              message: `Oops! ${response.data.e}`,
              type: 'error',
            },

          },
        });
        return Promise.reject(response);
      });
  };
}

export function hashToToken(hash) {
  return function(dispatch, API) {
    return API.auth.hashToToken({ hash })
      .then(response => {
        JWT.save(response.data.token);

        return dispatch({
          type: SET_USER_AUTH,
          payload: true,
        });
      })
      .catch(response => {
        return Promise.reject(response);
      });
  };
}
