import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';

function DataLoading(props) {
  const {
    message,
  } = props;

  function renderMessage() {
    if (!message) return null;
    return (
      <h3>{message}</h3>
    );
  }

  return (
    <div style={{ width: '100%', textAlign: 'center', paddingTop: 200, color: '#00095B' }}>
      {renderMessage()}
      <Icon
        spin
        type="loading"
        style={{ fontSize: 100, color: '#066FEF' }}
      />
    </div>
  );
}

DataLoading.propTypes = {
  message: PropTypes.string,
};

DataLoading.defaultProps = {
  message: '',
};

export default DataLoading;
