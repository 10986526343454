import {
  setSeason,
  verifyAuth,
  setGlobalSettings,
} from './';

export function checkEventId(hashed_url) {
  return function(dispatch, API) {
    return API.event.getEvent({ hashed_url })
      .then(response => {
        const formattedSettings = {};
        response.data.settings.map(o => {
          formattedSettings[o.setting_name] = o.setting_value;
        });
        dispatch(setSeason(response.data.data.season_id));
        dispatch(setGlobalSettings(formattedSettings));
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function endEvent() {
  return function(dispatch, API) {
    return API.event.end()
      .then(() => {
        return dispatch(verifyAuth());
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function clickedDemo(eventId) {
  return function(dispatch, API) {
    return API.event.clickedDemo({ eventId })
      .then(() => {
        return new Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
